import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import ListItem from '../images/boxes/listitem.svg';
import bransjerBoxes from '../images/boxes/bransjer_boxer.svg';
import industryBoxes from '../images/boxes/industry_boxer.svg';
import industryBoxes2 from '../images/boxes/industry_boxer_2.svg';
import Img from 'gatsby-image';
import styles from './Sectors.module.scss';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo/SEO';

export const query = graphql`
  query {
    ship: file(relativePath: { eq: "bransjer/ship.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tank: file(relativePath: { eq: "bransjer/tank.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ferry: file(relativePath: { eq: "bransjer/ferry.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rig: file(relativePath: { eq: "bransjer/rig.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    anlegg: file(relativePath: { eq: "bransjer/anlegg.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const scrollToRef = (ref: any) =>
  window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' });

const IndustryPage = (props: any) => {
  const { data } = props;
  const skipRef = useRef(null);
  const landRef = useRef(null);
  const executeScroll = (ref: any) => scrollToRef(ref);
  return (
    <>
      <Layout>
        <SEO title="Industries. Every industry requires its own approach with regards to handling of combustible gases. Read about our industries of expertise." />
        <div className="grid_text_image grid_vertical_padding sectionShort pb-96 mb-96">
          <div>
            <h1 className={styles.welcomeText}>Each industry requires its own approach</h1>
            <p className={styles.descriptionText}>
              Each industry is governed by different sets of regulations, and industry-specific
              knowledge is key to proper risk assessment. Such knowledge is essential for us to
              quickly understand your situation and challenges.
            </p>
            <p className={styles.descriptionText}>
              HYEX may support your projects from the early design stages by performing a design 
              screening and helping to optimise system layout related to safety. HYEX has held 
              many safety workshops (design screening, HAZID) with the purpose to identify risks 
              and ways to mitigate or prevent these. Given our broad understanding of the 
              explanatory factors behind potential hazards, and expertise using the FLACS CFD 
              consequence modelling software, we assess your risks efficiently.
            </p>
            <div className={styles.boxContainer}>
              <img src={bransjerBoxes} alt="boxes" />
              <div className={styles.navBox}>
                <ul className={styles.navList}>
                  <li className={styles.navItem} onClick={() => executeScroll(skipRef)}>
                    Maritime industries
                  </li>
                  <li className={styles.navItem} onClick={() => executeScroll(landRef)}>
                    Land-based infrastructure and industries
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.tank.childImageSharp.fluid} alt="Refinery Storage Tank" />
            </figure>
          </div>
        </div>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong">
          <div>
            <h1 className={styles.welcomeText}>Maritime industries</h1>
            <p className={styles.descriptionText}>
              Zero emission fuels like hydrogen and ammonia, as well as most low emission fuels, 
              are in the category of low flashpoint fuels and regulated by the IGF-code. The 
              IGF-code requires a risk assessment to be performed, including an explosion 
              assessment, to demonstrate that risks are equivalent to risks at modern, 
              conventionally fuelled vessels. HYEX Safety supports such projects from the early 
              design stages, with experience from 20+ hydrogen and ammonia vessel projects. We 
              offer to organise design screening to help optimise system layout related to 
              safety, contribute to HAZID-workshops to identify remaining hazards, and document 
              the risk through quantitative risk assessments including CFD explosion and 
              dispersion studies for approval processes towards classification society and flag 
              state administration. 
            </p>
            <p className={styles.descriptionText}>
              For bunkering sites, a risk assessment to define Safety zone and Monitoring and 
              Security area may be required, as for LNG per ISO 20519. Such assessments are 
              highly relevant both for LH2 and for ammonia.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile" ref={skipRef}>
              <Img fluid={data.ferry.childImageSharp.fluid} alt="Large ferry" />
            </figure>
          </div>
        </div>
        <div className={styles.boxesPattern}>
          <img src={industryBoxes} alt="boxes" />
        </div>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong mb-96">
          <div>
            <h1 className={styles.welcomeText}>Land-based infrastructure and industries</h1>
            <p className={styles.descriptionText}>
              Safety assessments for any kind of hydrogen and ammonia facilities, infrastructure 
              and manufacturers are a speciality of HYEX Safety. Assessments may focus on design 
              optimisation to limit risk from hydrogen and ammonia equipment, on workplace safety 
              at handling sites, or the evaluation of risk around a site to define risk contours 
              for land planning purposes.
            </p>  

            <div className={styles.descriptionText}>
              Upon request, we perform:
              <div className={styles.projectListContainer}>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Safety screening 
                  for sites handling hydrogen and ammonia using HYEX Safety engineering tools.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Specialist
                  CFD-studies for dispersion and explosion assessments.
                </div>
                <div className={styles.projectItem}>
                  <img className={styles.listItemImg} src={ListItem} alt="box" /> Accident/incident
                  investigation support with detailed CFD-modelling.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile" ref={landRef}>
              <Img fluid={data.anlegg.childImageSharp.fluid} alt="Ammonia production plant" />
            </figure>
          </div>
        </div>
        <div className={styles.boxesPattern2}>
          <img src={industryBoxes2} />
        </div>
      </Layout>
    </>
  );
};

export default IndustryPage;
